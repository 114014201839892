<template>
  <div>
    <cookie-accept-decline :ref="'cookiemsg'" :elementId="'cookiemsg'" :position="'bottom-right'" :type="'floating'" :disableDecline="true" :transitionName="'slideFromBottom'" :showPostponeButton="false" @clicked-accept="cookieClickedAccept">

    <!-- Optional -->
    <div slot="postponeContent">
      ×
    </div>

    <!-- Optional -->
    <div slot="message">
      <div class="gdprcookie" style="display: block;">
        <div class="gdprcookie-intro">
          <h1 class="mb20 mt10">ملفات تعريف الارتباط وحماية البيانات</h1>
          <p>يستخدم هذا الموقع الإلكتروني ملفات تعريف الارتباط الضرورية تقنياً فقط لتزويدك بأفضل الوظائف الممكنة. يمكن الاطلاع على مزيد من المعلومات في سياسة الخصوصية. لا يتم تتبع المستخدم.</p>
        </div>
        <div v-if="false" class="gdprcookie-types">
          <h2 class="mb20 mt20">حدد ملفات تعريف الارتباط التي تريد قبولها.</h2>
          <ul class="list-inline">
            <li class="list-inline-item">
              <input type="checkbox" id="gdpr-cookietype-0" name="gdpr[]" value="essential" checked="checked" disabled="disabled">
              <label for="gdpr-cookietype-0" title="ملفات تعريف الارتباط هذه ضرورية لكي يعمل هذا الموقع الإلكتروني بشكل صحيح.">ضروري</label>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- Optional -->
    <div slot="declineContent">
     الانسحاب الاختياري
   </div>

   <!-- Optional -->
   <div slot="acceptContent" tabindex="-1">
     <button type="button" class="btn btn-primary" title="تأكيد ملفات تعريف الارتباط" ref="closeBtn">حسناً</button>
   </div>
 </cookie-accept-decline>
</div>
</template>

<script>
  /*Vue Cookie banner*/
  import '@/components/controls/CookieAcceptDecline/cookie-accept-decline.scss';

  export default {
    name: "cookieBanner",
    components: {
     CookieAcceptDecline: () => import('@/components/controls/CookieAcceptDecline'),    
   },
   data() {
    return {
    };
  },
  methods:{
    cookieClickedAccept(){
      if(this.$refs.gdprAnalytics.checked){
        /*Enable Matomo*/
        /*this.$matomo.rememberConsentGiven();*/
      }
    }
  },
  mounted(){
    setTimeout(() => {
      this.$refs.closeBtn.focus();
    }, 2000);
  },
}

</script>
<style type="text/css">

  #cookiemsg .cookie__floating__buttons .cookie__floating__buttons__button,#cookiemsg .cookie__floating__buttons .cookie__floating__buttons__button:hover, #cookiemsg .cookie__floating__buttons .cookie__floating__buttons__button:active{
    background-color: transparent;
    border: none!important;
    margin-bottom: 20px!important;
  }

  #cookiemsg {
    color: #000;
    font-family: "Open Sans", sans-serif;
    font-weight:  normal;
    line-height: 1.5;

  }

  #cookiemsg.cookie__floating .cookie__floating__content{
    text-align: center;
    font-size: 16px!important;
    max-height: 100%!important;
  }

  #cookiemsg.cookie__floating {
    border-radius: 10px;
    max-width: 300px!important;
    box-shadow: -5px 5px 15px 0px rgba(0, 24, 62, 0.2);
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  #cookiemsg .list-inline-item label{
    margin-left: 10px;
    font-size: 15px;
  }

  #cookiemsg a{
    text-decoration: underline;
  }

  #cookiemsg h1{
    font-size: 20px;
  }

  #cookiemsg h2{
    font-size: 15px;
  }

  #cookiemsg .btn{
    color: white;
    padding: 10px 25px;
    background-color: $primary;
  }

  #cookiemsg .btn:hover {
    color:  white;
  }
</style>
